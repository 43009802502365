import React, { Component } from 'react';
import Main from './components/MainComponent';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';
import SimpleReactLightbox from 'simple-react-lightbox'
import ScrollToTop from'./components/ScrollToTop';
import './App.css';

const store = ConfigureStore();

class App extends Component {
  render() {
    return(
    <Provider store={store}>
    <BrowserRouter>
     <ScrollToTop>
    <SimpleReactLightbox>
      <div className="App">
        <Main />
      </div>
      </SimpleReactLightbox>
      </ScrollToTop>
    </BrowserRouter>
  </Provider>
    ); 
    };
  }
  
  export default App;