import React, {useState} from 'react';
import {Carousel,CarouselItem, CarouselControl,
  CarouselIndicators, Button  } from 'reactstrap';
  import { Link } from 'react-router-dom';


  function About(props) {
    return (


      <div className= "row ">
      <div className= "d-block d-md-none mt-5">
      <RenderImage />
      <RenderAbout /> 
      </div>
      <div className= "d-none d-sm-none d-md-block d-lg-block d-xl-block" >
      <div className = "row">
      <RenderAbout /> 
      <RenderImage />
      <RenderResume/>
      </div>
      </div>

      </div>
      );
    }

    const items = [
    {
      src: '/img/profile1.JPG',
      altText: 'Slide 1'

    },
    {
      src: '/img/profile2.JPG',
      altText: 'Slide 2'

    }
    ];

    const RenderImage = (props) => {



      const [activeIndex, setActiveIndex] = useState(0);
      const [animating, setAnimating] = useState(false);

      const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      }

      const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
      }

      const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
      }

      const slides = items.map((item) => {
        return (
        <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        >
        <img src={item.src} alt={item.altText} className="d-block w-100 h-100"/>
        </CarouselItem>
        );
      });

      return (


      <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className ="carouselObj square col-10 col-s-10 col-md-4 col-lg-3 col-xl-2 "
      >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
      );
    }


    function RenderAbout() {
      return (
      <>
      <div className = "aboutme col-10 col-md-6 col-lg-6 col-xl-7">
  
   
      <h3 className= "text-center"> I am an experienced designer + developer with a game design background. I focus on user accessiblity + experience through the use of research, iterative design, testing and developing for a mobile , web and responsive screens.
      </h3>
      <br/>
      <h3 className= "text-center"> I enjoy painting, hiking in nature, playing open-world games and cooking nourishing meals.
      </h3>
      <br/><br/>
       {/* <a href = "/resume"><h4>Resume</h4></a> */}
        <h3>Contact me at  <a href = "mailto:evitarlobo@gmail.com" className="">   evitalobo@gmail.com</a></h3>
         

      </div>

      </>

      );
    }

    function RenderResume(props){
      return(

       <div className= "row">
       <div className= "col">
     
      </div>
        </div>
      );
    }

    export default About;