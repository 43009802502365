import React, { Component}  from 'react';
import { Navbar, NavbarBrand, Nav, NavbarToggler, Collapse, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';

class Header extends Component {

    constructor(props) {
        super(props);

        this.toggleNav = this.toggleNav.bind(this);
        this.state = {
            isNavOpen: false,
            isModalOpen: false
        };

        this.toggleNav = this.toggleNav.bind(this);
        this.toggleModal = this.toggleModal.bind(this);

    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }


    render() {
        return (
            <React.Fragment>





            <Navbar light sticky="top" expand="md" className =" d-block light ">
            <div className="container-fluid ">
            <div className="name row">
            <a href = "/landing" className="col-6 col-xl-12 mr-5 mt-2">        
            <h1> evitA</h1>
            <div className= "col col-xl-12 ml-4">
            <h1>  loBo</h1>
            </div>   
            </a>
            </div>
            <NavbarToggler className = " " onClick={this.toggleNav} />
            <Collapse isOpen={this.state.isNavOpen} navbar>
            <Nav  className = "col-12 navbar-nav mt-3 " >

            <NavItem >
        
            <NavLink className="nav-link " to="/design"><h4> Design </h4>
            </NavLink>

            <NavLink className="nav-link " to="/games"><h4>Games</h4>
            </NavLink>

            <NavLink className="nav-link " to="/about"> <h4> About </h4>
            </NavLink>
            
            </NavItem>
            
            </Nav>

            </Collapse>
            </div>
            </Navbar>


            </React.Fragment>
            );
        }
    }

    export default Header;