
import React from 'react';
import { Card, CardImg, CardTitle, Breadcrumb, BreadcrumbItem, CardBody, CardText } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';


function RenderDirectoryItem({design}) {
    return (
      
        <Card className = "lcard col-12 col-lg-12 col-xl-8 text-center card flex-row flex-wrap">
            
        
            <Link to={`/design/${design.id}`}>
            <CardBody className= "col ">
                <CardTitle className= " mt-1 text-center">{design.name}</CardTitle>
               <h2> <CardText className= "text-center ">{design.description}</CardText> </h2>
            </CardBody>
            <CardImg className="imgstyle" src={design.image} alt={design.name} />
            
            </Link>
        </Card>

    );
}

function Project(props) {

    const project = props.designs.map(design => {
        return (
            <div key={design.id} className="  ">
                <RenderDirectoryItem design={design} />
            </div>
        );
    });

    return (
        <div className="container overflow:auto">
            <div className="row  ">
                <div className="col  text-center">
              

                </div>
            </div>
                {project}
                                    <br/><br/>
        </div>
    );
}

export default Project; 