
import React from 'react';
import ReactPlayer from "react-player";
import { SRLWrapper } from "simple-react-lightbox";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";




export const DESIGNINFO = 
[
{
        id: 0,
        listingid: 0,
        logo: <img className= " col-12 col-lg-4 mb-3 mx-auto" src="/img/hh/logo.png" alt=""/>,
        sketch2:<img className= " col-12 col-lg-6 mb-3" src="/img/hh/solo.png" alt=""/>, 
        sketch3:<><p> Almost all of the results from the A/B test were unanimous - users preferred option B. The color scheme with less colors and the sidebar component. They liked the diverse photo gallery size and the general layout. However the client's preferred option A. When it came time to discuss the feedback, the client's agreed to the design that is ultimately preferrable for the user. </p><br/></>, 
        sketch4:<h3 className= "mb-2"> Sprint Planning </h3>, 
        sketch5:<div className = "row"><p className="col-12 col-lg-4 text-center">There were several components that needed to be updated based on the user testing data. In order to help C-suite meet their MVP goals on-time, I ranked and prioritized all the changes into a Priority Plan. The Priority Plan identifies action items, cites solutions, and ranks the implementations that is used for AGILE Scrum. Since my contract with the company only was coming to an end, I wanted to make sure that they would have a clear direction of the next steps for design. </p><Carousel className="col-12 col-lg-8" interval={7000} infiniteLoop={true} autoPlay={true} showArrows={true} showThumbs={false} ><div><img  src="/img/hh/HiHomepp-1.png" /></div><div><img src="/img/hh/HiHomepp-2.png" /></div></Carousel> </div>, 
        sketch9_2: <div className = "row"><Carousel className="col-12 col-lg-6" interval={7000} infiniteLoop={true} autoPlay={true} showArrows={true} showThumbs={false} ><div><img  src="/img/hh/homedescription1.png" /></div><div><img src="/img/hh/homedescription2.png" /></div><div><img src="/img/hh/homedescription3.png" /></div></Carousel> <p className="col-12 col-lg-4 text-center "> I designed 3 potential home description sketches. The goal of sketching is to identify which layout can show the user the most relevant information in one screen . The most important components according to HiHome's previous research seemed to be the price, location, HiScore details and the commute. </p></div>,
        sketch10:<img className= " col-12 col-lg-6 mb-3" src="/img/hh/dual.png" alt=""/>, 
        sketch14:<h5 className= "mx-auto">Final Screens</h5>, 
        sketch15:<img className= " col-12 col-lg-8 col-xl-6 mb-3" src="/img/hh/finalhomedescription.png" alt=""/>,
        sketch16:<img className= " col-12  col-xl-6 mb-3" src="/img/hh/finalhomepreview.png" alt=""/>,
        sketch17:<div className="row"> <p className = "col col-lg-6 text-center"> A team member designed the Onboarding Wizard while I provided design input such as incorporating a template to reduce the amount of time the user spends adjusting their crtieria as well as having no more than 4 screens for the onboarding process. Instead of the bubble designs I suggested using a list where the user could select and drag their ranked preferences - however, the client would not change their mind until they saw the user testing feedback. </p> <ReactPlayer className= " col col-lg-6 mx-auto" url='/img/hh/onboard.mp4' playing='true' loop='true' width= '60%' height='60%' volume='0' controls = {true}/> </div>,
        sketch19: <p>The biggest problem during the usability testing was the onboarding process was too long and unclear - the bubble design was especially confusing. I was able to show the client that users did not like the bubble interface and another format should be considered. I suggested using a drag and drop ranking system to replace the bubble design. I also suggested that the onboarding screen should be broken up into 4 screens vs the previous 6. I worked together with the team to come up with solutions for the Onboarding wizard.</p>,
        sketch20:<img className= " col-12 col-md-3 col-xl-12 mb-0" src="/img/hh/onboardingfinal.png" alt=""/>,
        image1:<div className = "row"> <p className= "col-12 col-lg-3 col-xl-3 text-center"  >The previous user flow provided by HiHome is not intuitive and does not capitalize on HomeHunter’s potential to acquire and retain users. I redesigned a user flow to determine the MVP of the product screens that my team could design screens for and to demonstrate a user focused approach to the web interface.</p><img className= " col-12 col-lg-4 col-xl-3 mx-auto" src="/img/hh/olduf.png" alt=""/><img className= " col-12 col-lg-5 col-xl-4 mb-3 mx-auto" src="/img/hh/UserFlows.png" alt=""/></div>, 
        image5: <p> I designed several concepts for the client to consider before progressing to a high fidelity design. Initially, I designed two views for the Home Description page- a solo screen and a dual screen. The client emphasized that they want HiHome to stand out from competitors. The biggest pain point was that users would be scrolling for hours on competitor’s websites so the goal was to reduce scroll time and view all the relevant information on one screen.</p>,
        image7:<><br/><h3>Users:</h3> <img className= " col-12 col-lg-8 mx-auto" src="/img/hh/users.png" alt=""/>,</>,
        image8:   <> <br/><p><h3>Home Description</h3></p></>,
        problem:<><br/><b>Platform:</b> Desktop App <br/><b>Role:</b> UI/UX designer working with 2 other designers and C-suite <br/><b>Tools:</b> Figma, Adobe Illustrator, Airtable <br/><b>Client:</b> <a href= "https://www.gohihome.com/" target = "_blank" > HiHome </a></> ,
        problem1:<><b> Product Description:</b><p>HiHome is an instant home matching and home comparison tool where buyers can enter their specific home criteria and adjust the importance of that criteria. Then, they can easily and instantly view their top home matches along with an overall HiScore (relevance score) and all of the other data points/criteria they care about all in one place.</p> <br/> <b> Background: </b> <p>Prior to me joining, HiHome had an early draft of the onboarding process but they required a team of designers to audit, scale and develop their full product. I worked with a team of 2 other designers and C-suite for 2 months (10 hours per week) on this desktop application. My specific role involved conducting my own research, designs and testing while also providing feedback for other members. </p> </>,
        tools:<> <li>Figma</li> <li>Adobe Illustrator</li> <li>Airtable</li>  </>,
        role1:<img className= " col-12 col-md-4 col-xl-10 mb-3" src="/img/hh/process.png" alt=""/>, 
        role2: <img className= " col-12 col-md-4 col-xl-10 mb-3" src="/img/hh/roles.png" alt=""/>, 
        research: <div className = "col-12 col-md-10 col-xl-8 mx-auto"><ReactPlayer url='/img/hh/finalvideo.mp4' width= '100%' height='100%'  controls = {true}/></div>,
        title1:'Research',
        title4:"Final iteration",
        title5:"Conclusion",
        title6:"Ideation",
        title7:"Mid Fidelity Design",
        heading:"Competitive Analysis",
        heading1:"User Flows",
        heading6:"User Testing",
        heading7:"Style Guide",
        heading9:"Sketch",
        bullettitle1:<p><h3 className ="mx-auto">A/B Testing</h3></p>,
        process1: <div className ="row"> <div className = "col col-lg-9"> <b> My Goals: </b> <ul><li> To identify product scope for my design team by building an MVP(minimum Viable Product) of <u>userflows</u> </li> <li> To identify current issues in the home description page of real estate search websites through a <u>competitive analysis</u></li> <li> To idenitfy flaws in previous HiHome onboarding design through an <u> accessibility audit</u> </li> </ul> </div> <div className = "col col-lg-3"> <b>Resources:</b> <p> The resources obtained from the HiHome team include - previous onboarding designs and previous User Flows.</p> </div> <br/></div>,
        process1_4:"The company provided us with previous designs of the onboarding sequence - however, I identified several usability and accessibility issues. The main concerns of the users is that the appearance and usage is too different from popular real-estate patterns. The main issue would be inputing data through the use of the bubble interface. I communicated these issues to the C-suite early on and another designer that would be working on onboarding but the client was insistent on the bubble design. These issues would inform how we create the style guide and designs.",
        process1_6:"The main components for the product include the Home Description, Onboarding and Landing Page. I focused on the Home Description component and provided input to improve the designs of the landing page, onboarding wizard and dashboard.",
        process2:<h3> Visual Accessibility Audit of HiHome's Previous Onboarding Designs</h3>,
        process4:<div className ="row"> <div className = "col col-lg-7"> <b> My Goals: </b> <ul><li> To identify usability issues in the prototype through <u>usability testing</u> </li> <li> To assess which design components/styles are preferrable for the user through <u>A/B testing</u> </li> <li> To compile all of the user feedback in an AGILE styled <u>sprint planning chart</u> </li>  </ul> </div> <div className = "col col-lg-5"> <b>Methodologies:</b> <p> All user interviews were conducted using a remote moderated Zoom call with a test script. The Usability and A/B Testing took place over a Figma through the use of a prototype(Usability testing) and slides(A/B testing).</p> </div> <br/></div>,
  
          process12:<div className ="row"> <div className = "col col-lg-7"> <b> My Goals: </b> <ul><li> To solve the pain points from the competitive anyalysis through <u>sketching</u> </li> <li> To create a visually accessible <u>style guide</u> by solving accessibility issues from the previous design </li>  </ul> </div> <div className = "col col-lg-5"> <b>Resources:</b> <p> The resources obtained from the HiHome team include - research consisting on what is important to the user, color pallette, fonts and logo.</p> </div> <br/></div>,
        process13:"Home Description",
        process14:"",
        bullet11_3:<div className = "row"><p className="col-12 col-lg-3 text-center">All the elements in the style guide serve as a starting point for components in the mid-fidelity designs and will change based on user feedback. Color pallette, font style and logo were provided by HiHome- the rest my team collaboratively worked on. </p><img className="col-12 col-lg-4" src="/img/hh/sg1.png" /><img className="col-12 col-lg-5 text-center" src="/img/hh/sg.png" /> </div>,
        bullet10_2:<li>Which navigation structure works best for an online art shop?</li>,
        bullet11_2:<li>What elements should be displayed on the home page?</li>,
        bullet12_2:<li>How can I accommodate the budget of customers?</li>,
        bullet1_3: <div className = "row"> <p className= "col-12 col-lg-4 text-center"> I used the leading home-search applications to focus on the pain points of the home description page. I would solve these pain points through my design for the Home Description page. </p> <img className= " col-12 col-lg-8 mb-3  " src="/img/hh/companalysis2.png"/> </div>,
        bullet6_3: <img className= "col-12 col-lg-6" src="/img/hh/olddesign1.png" alt="" />,
        bullet7_3:  <img className= "col-12 col-lg-6" src="/img/hh/olddesign2.png" alt="" />,
        bullet24_3: <> <br/> <br/> <h3> Onboarding Wizard</h3> </>,
        bullet26_3: "" ,
        summary:<><p> Working with the HiHome team allowed me to collaborate with a design team effectively. We were able to divide the work and provide feedback to each other to allow our designs to be elevated. We were working under a time limit and had limited time to work according to our contract with the company as well as our divergent schedules. There were complications during the design such as a team member leaving halfway through the contract but we were still able to complete the work we had promised for our client.<br/> <br/> The biggest thing I’ve learned from this project is that it is important to develop the scope of the project as soon as possible so you can understand what elements need to be designed and tested ASAP. For much of this project, we didn’t test until we got to the last week so I would prioritize testing in the earlier stages rather than later since the design could always be polished later but conducting user-tests and compiling the data for iterations can always be more time consuming. For example, if we tested the bubble design our client insisted on within week 2- we would have learned that they aren’t accessible and could have created an alternative much faster. I learned that the design the client wants isn't always what is the most user-friendly but with designs backed by testing, we can find a happy medium where both the client and customer are satisfied with their user experience.</p></>,
        link: <a href="https://www.figma.com/proto/js3RB7f5hR5rPcD56yPeny/HiHome-Homehunting?node-id=0%3A1&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=296%3A1126" target = "_blank"> High Fidelity Prototype </a>,
        caption1:<></>,
        ex1:<p>C-suite prefered having a home content layout similar to the competitor’s so I designed these two layouts where the HiScore details are displayed using a fixed sidebar and another with a card component. </p>,
        ex2:<img className= " col-12   mb-3 mx-auto" src="/img/hh/com1.png" alt=""/>,
        ex3:<img className= " col-12 col-lg-10 mb-3 mx-auto" src="/img/hh/com2.png" alt=""/>,
        ex4:<p>The HiScore Sidebar was designed to be the most accessible user design- excluding the photo carousel. The second iteration of the HiScore card component view was built around the C-suite preference. However, at this point all of the testing and feedback was all internal so we decided to use A/B testing to determine which components were the best for the final Home Description page.</p>,
        ex8:<img className= " col-12 col-lg-8 col-xl-7 mx-auto" src="/img/hh/homedescriptab.jpg" alt=""/>,

    },

    {
        id: 1,
        listingid: 1,
        logo: <img className= " col-12 col-lg-3 mb-3 mx-auto" src="/img/ll/pic1.png" alt=""/>,
        sketch1:<a href="/img/ll/artofadventure.png" caption="Lorem ipsum dolor sit amet"><img className= " col-12 mb-3 col-xl-4 " caption="Lorem ipsum dolor sit amet" src="/img/ll/artofadventure.png"/></a> ,
        sketch2:<img className= " col-12 col-lg-6 mb-3" src="/img/ll/sg2.png" alt=""/>, 
        sketch3:<img className= " col-6 col-md-3 col-xl-2 mb-3" src="/img/ll/pic7.png" alt=""/>, 
        sketch4:<img className= " col-6 col-md-3 col-xl-2 mb-3" src="/img/ll/pic10.png" alt=""/>, 
        sketch5:<img className= " col-6 col-md-3 col-xl-2 mb-3" src="/img/ll/pic3.png" alt=""/>, 
        sketch6:<img className= " col-6 col-md-3 col-xl-2 mb-3" src="/img/ll/pic2.png" alt=""/>,
        sketch7:<img className= " col-12 mb-3 col-xl-4 " src="/img/ll/fiercelycurious.png" alt=""/>,
        sketch8:<img className= " col-12 mb-3" src="/img/ll/lfm.png" alt=""/>,
        sketch9:<img className= " col-12 col-lg-6 mb-3" src="/img/ll/lfd.png" alt=""/>,
        sketch10:<img className= " col-12 col-lg-6 mb-3" src="/img/ll/sg3.png" alt=""/>,
        sketch11:<img className= " col-12 mb-3 col-xl-4 " src="/img/ll/hatecopy.png" alt=""/>,
        sketch12:<img className= " col-6 col-md-3 col-xl-2 mb-3" src="/img/ll/pic6.png" alt=""/>, 
        sketch13:<img className= " col-6 col-md-3 col-xl-2 mb-3" src="/img/ll/pic8.png" alt=""/>, 
        sketch14:<img className= " col-6 col-md-3 col-xl-2 mb-3" src="/img/ll/pic4.png" alt=""/>, 
        sketch15:<img className= " col-6 col-md-3 col-xl-2 mb-3" src="/img/ll/pic5.png" alt=""/>,
         sketch16:<img className= " col-6 col-md-3 col-xl-2 mb-3" src="/img/ll/pic9.png" alt=""/>,
          sketch17:<img className= " col-12 col-lg-6 mb-3" src="/img/ll/lowfi.png" alt=""/>,
         sketch18:<img className= " col-12 mb-3" src="/img/ll/lf1.png" alt=""/>,
          sketch19:<img className= " col-6 col-md-3 col-xl-2 mb-3" src="/img/ll/pics1.png" alt=""/>,
           sketch20:<img className= " col-6 col-md-3 col-xl-2 mb-3" src="/img/ll/pics2.png" alt=""/>,
            sketch21:<img className= " col-6 col-md-3 col-xl-2 mb-3" src="/img/ll/pics3.png" alt=""/>,
        image1: "", 
        image2:"" ,
        image3: <img className= "col-12 col-lg-8" src="/img/ll/uf.png" alt="" />,
        image4:  <img className= " col-12 mb-3" src="" alt=""/>,
        image5:  <img className= " col-12 col-lg-6 mb-3" src="/img/ll/sg1.png" alt=""/>,
        image6: "",
        image7:<img className= " col-12 mb-3" src="" alt=""/>,
        image8: <img className= " col-12 col-lg-6 mb-3" src="/img/ll/pic1.png" alt=""/>,
        altname:"" ,
        movie:  "",
        doclink:<a href="https://docs.google.com/document/d/1mkynqFbKq6hT1iaFVu1lWb1bmzFKGSgX2vhd9v5VrCQ/edit?usp=sharing" > A/B Testing images </a>,
        problem:<><br/><b>Platform:</b> Mobile <br/><b>Role:</b> Product designer  <br/><b>Tools:</b> Sketch, Adobe Illustrator, Adobe Photoshop, Invision Studio <br/></>,
        problem1:"I am currently an artist selling my products through traditional channels such as events and  instagram. However, in order to scale and reach more customers, I needed to design a mobile e-commerce web app for my online store.",
        tools:<> <li>Sketch</li> <li>Invision Studio</li> <li>Adobe Photoshop</li> <li>Adobe Illustrator</li> </>,
        roles:"Product Designer",
        role0:"",
        role1:<> <img className= " col-12 col-md-4 col-xl-10 mb-3" src="/img/ll/process.png" alt=""/></>,
        role2: "",
        research: <div className = "col-12 col-md-10 col-xl-4 mx-auto"><ReactPlayer url='/img/ll/lldemo.mov' width= '100%' height='5%'  controls = {true}/></div>,
        title1:'Research',
        title2:"",
        title3:"",
        title8:"Wireframes",
        title4:"High Fidelity",
        title5:"Conclusion",
        title6:"Design",
        title7:"Low Fidelity",
        heading:"Industry Audit",
        heading1:"Comparative Analysis",
        heading2:"User Flows",
        heading3:"Style Guide",
        heading4:"Low Fidelity Screens",
        heading5:"A/B Testing",
        heading6:"User Testing: High Fidelity",
        heading7:"User Testing: Wireframe",
        bullettitle1:"",
        bullettitle2:"",
        process1: "Since this is essentially an e-commerce website, the most pertinent research methods would be Comparative Analysis and Industry Audit.",
        process1_2:" ",
        process1_3:" I shortlisted ten shops that were similar to the needs of my online and met the following criteria:",
        process1_4:"",
        
        process1_6:"",
       process1_7: "Wireframes were drafted for both  mobile and desktop viewports. The mobile design will be the primary use of the application- featuring all the important components found during research.The desktop will be the secondary use of application, where the results and stats of the mobile interactable design will be used to further develop the desktop design.",
        process1_5:" ",
       process1_8:"I decided to implement these main changes to make the interactive prototype easier to use:",
        process2:"I further whittled down the initial ten choices to the following five based on the ones that had that had the best mobile e-commerce patterns:",
        process3:"I interviewed five users, asked them a few questions to gauge their online spending habits and then  gave them a prompt with contextual background and a goal to achieve. These screening questions include:",
        process4:" After testing five additional users, I realized that my current design was a bit overwhelming for the eye. Hence, I had to reduce the amount of visual clutter, provide more depth, reduce the amount of white space and incorporate more mobile e-commerce patterns. I included another item category page as well. ",
        process5:"",
        process6:"",
        process7:"",
        process8:"",
        process9:"",
        process10: "",
        process11:"",
        process12:"Based on the research, I decided on the following potential user flows for meeting the product goals.",
        process13:"Mobile Screens:",
        process14:<b>Desktop Screens:</b>,
        process15:<p>The test prompts for the prototype include realistic scenarios based on previous customer screenings:</p>,
        process16:"The key results I found based on testing include:",
        process17:"Since much of my artwork has consistent imagery of the sun and bodies of water, I choose the colors yellow and turquoise as they also reflect the brand traits. In addition, yellow symbolizes optimism while turquoise symbolizes tranquility. While designing the logo, I used a wolf since the word lobo translates to wolf in spanish ",
        process18:"I transformed the wire-frame into low fidelity by analyzing several design patterns from popular e-commerce websites and then applying the style guide. ",
        process19:<p> I conducted A/B testing to identify which layout would be best for each component of the shop, specifically focusing on the color of tiles, the outline of each item, FAQ and About design, Header design, primary and secondary color choices and carousel image.</p>,
        process20:<p>Most users said that they preferred yellow as the primary color and blue as the secondary color, also preferred a sleeker look.</p>,
        bullet1_1:<img className= " col-12 mb-3" src="/img/ll/hifi1.png" alt=""/>,
        bullet2_1: <img className= " col-12  mb-3" src="/img/ll/hifi2.png" alt=""/>,
       bullet3_1: <p className= "mx-auto"> Below are the final mobile screens </p>,
        bullet7_1:"",
        bullet8_1:"",
        bullet9_1:"",
        bullet10_1:<p><li>Customer base must be making purchases primarily through a mobile interface</li></p>,
        bullet11_1:<li>Must be selling an assortment of art products (canvases, prints and jewelry, etc.)</li>,
        bullet12_1:"",
        
        bullet1_2:"",
        bullet2_2:"",
        bullet3_2:"",
        bullet4_2:"",
        bullet5_2:"",
        bullet6_2:"",
        bullet7_2:"",
        bullet8_2:"",
        bullet9_2:"",
        bullet10_2:<li>Which navigation structure works best for an online art shop?</li>,
        bullet11_2:<li>What elements should be displayed on the home page?</li>,
        bullet12_2:<li>How can I accommodate the budget of customers?</li>,
        bullet1_3: "I shortlisted the following three art e-commerce websites to conduct an Industry Audit: Art of Adventure, Hatecopy and Fiercely Curious.",
        bullet2_3:"",
        bullet3_3:"",
        bullet4_3: "I started by focusing on the primary pages that the user would have to go through before purchasing their item e.g. the Home page, Product Page, Cart and Payment. For the payment, I used Shopify’s API to handle the frontend and backend for the purchases.  I created wireframes  for likes , dislikes and action items that I could apply to my own design.",
        bullet5_3: "",
        bullet6_3: <img className= " col-12 col-lg-6 mb-3 mx-auto" src="/img/ll/comp1.png" alt=""/>,
        bullet7_3: <img className= " col-12 col-lg-6 mb-3 mx-auto" src="/img/ll/comp2.png" alt=""/>,
        bullet8_3: "",
        bullet9_3: <li>How often do they shop? </li>,
        bullet9_4: <img className= " col-12 col-lg-6 mb-3 mx-auto" src="/img/ll/comp3.png" alt=""/>,
        bullet10_4: <img className= " col-12 col-lg-6 mb-3 mx-auto" src="/img/ll/comp4.png" alt=""/>,
        bullet10_3: <li>How often do they have a budget?</li>,
        bullet11_3: <p><li>How frequently do they utilize the ‘buy now’ button?</li></p>,
        bullet12_3: <li> <b>Budget friendly art</b> <br/>Imagine you drew a friend’s name for a secret santa- you know this friend just moved into a new apartment and you want to find some decorations for them. The budget for the secret santa is 20$. Can you find a gift option for them and add it to your cart? <br/></li> ,
        bullet13_3: <li> <b>Purchase a print</b> <br/>You just moved into a new place and you’ve been looking for some hand painted art prints to hang as a centerpiece of the room. Can you purchase it?<br/></li> ,
        bullet14_3: <li> <b>Learn more about the store</b> <br/>You want to promote the store in an upcoming holiday market. You want to learn if the sellers ideals and goals aligned with the community the market is representing.<br/></li> ,
        bullet15_3: <li> <b>Locate the FAQ to identify questions on shipping </b> <br/>Before purchasing your items, you want to see if they will be shipped out in time for a holiday.<br/> <br/></li> ,
        bullet16_3: <li>Everyone has a budget except for essential items</li>,
        bullet17_3: <li> Trouble navigating back to home screen</li> ,
        bullet18_3: <li> Include navigation elements in footer</li> ,
        bullet19_3: <li> Include a sort functionality </li> ,
        bullet20_3: <li> Include a way to add more items from the cart<br/><br/> </li> ,
        bullet26_3: "" ,
        summary:"The final interactable Prototype allowed me to really dig into refining my prototype to be on par with the popular e-commerce websites. I'm currently finishing up the desktop UI design. Additional enhancements could still be made- such as toning the color palette down, increase padding around text/image elements, removing extra elements- such as horizontal lines and overall better use of space. However, I prefer to accumulate and defer them to during the implementation phase. The next steps for this project is to develop the shop and integrate the checkout with Shopify's API. I am currently working on the devlopment of this shop- for more details about the devlopment aspect please contact me. ",
        link: <a href="https://evitalobo763289.invisionapp.com/prototype/lucidlobo-cklszxsh0000spc01bqku8um3/play/5a62f2f3" target = "_blank"> High Fidelity Prototype </a>,
        caption1:"",
        caption2:"",
        caption3: " ",
    },
    {
        id: 2,
        listingid: 2,
        sketch1:<img className= " col-12 mb-3 d-lg-none " src="/img/gp/gpjourneymap.png" alt=""/>,
        
        sketch3:<img className= " col-6 col-md-4 col-xl-3 mb-3" src="/img/gp/fgp1.png" alt=""/>, 
        sketch4:<img className= " col-6 col-md-4 col-xl-3 mb-3" src="/img/gp/fgp2.png" alt=""/>, 
        sketch5:<img className= " col-6 col-md-4 col-xl-3 mb-3" src="/img/gp/fgp3.png" alt=""/>, 
        sketch6:<img className= " col-6 col-md-4 col-xl-3 mb-3" src="/img/gp/fgp4.png" alt=""/>,
        sketch7:<img className= " col-12 mb-3 pt-0 d-none d-lg-block d-xl-block " src="/img/gp/gpjmv2.png" alt=""/>,
        sketch8:<img className= " col-12 mb-3" src="/img/gp/gallerypal.jpeg" alt=""/>,
        sketch9:"",

        sketch11:"",
        sketch12:<img className= " col-6 col-md-4 col-xl-3 mb-3" src="/img/gp/fgp5.png" alt=""/>, 
        sketch13:<img className= " col-6 col-md-4 col-xl-3 mb-3" src="/img/gp/fgp6.png" alt=""/>, 
        sketch14:<img className= " col-6 col-md-4 col-xl-3 mb-3" src="/img/gp/fgp7.png" alt=""/>, 
        sketch15:<img className= " col-6 col-md-4 col-xl-3 mb-3" src="/img/gp/fgp8.png" alt=""/>,
        image1: <img className= " col-12 mb-3" src="" alt=""/>, 
        image2: <img className= " col-10  mb-3 mx-auto" src="/img/gp/discord.png" alt=""/>,
        image3: <> <img className= "col-12 col-lg-8" src="/img/gp/crazy8.jpg" alt="" /> <br/> <br/> </>,
        image4:  "",
        image5:  <img className= " col-12 col-lg-8 mb-3" src="/img/gp/sg.png" alt=""/>,
        image6: <img className= " col-12 mb-3" src="" alt=""/>,
        image7:<img className= " col-12 mb-3" src="" alt=""/>,
        altname:"" ,
        movie:"",
        problem1:"The goal is to improve the in-person experience of viewing art at museums/galleries using a mobile app. This is a Google Ventures design sprint spanning over the course of a week.",
        problem:<><br/><b>Platform:</b> Mobile <br/><b>Role:</b> UI/UX designer  <br/><b>Tools:</b> Sketch, Figma, Adobe Illustrator <br/></>,
        tools:<ul> <li>Figma</li> <li>Sketch</li> <li>Invision Studio</li> <li>Adobe Photoshop</li> <li>Adobe Illustrator</li> </ul>,
        roles:"Solo UI/UX Designer",
        role0:"",
        role1:<> <p><ul>  <li> Created user journey maps for six individuals</li> <li> Conducted lightning demos for three mobile apps</li> <li> Sketched 8 distinct design ideas in 8 mins (Crazy 8s design sprint method)</li> <li> Highlighted 3 critical screens for designs</li> <li> Storyboarded 8 different screens based on navigation and user needs</li> <li> Prototyped Low-Fidelity B&W Wireframes in Sketch</li><li> Established a style guide </li> <li> Created a high fidelity interactive prototype on Figma </li> <li> Conducted 5 moderated usability tests throughout the low-fidelity to high fidelity designs </li> </ul></p> </>,
        role2: "",
        research:<div className = "col-12 col-md-10 col-xl-4 mx-auto"><ReactPlayer url='/img/gp/gpdemo.mov' width= '100%' height='7%'  controls = {true}/></div>,
        title1:'Research',
        title2:"",
        title3:"",
        title4:"Final Iteration",
        title5:"Conclusion",
        title6:"Design",
        title7:"High Fidelity",
        title8:"",
        heading:"Journey Map",
        heading1:"Lightning Demo",
        heading2:"Crazy 8's",
        heading3:"Style Guide",
        heading4:"High Fidelity Screens",
        heading5:"",
        heading6:"User Testing: High Fidelity",
        heading7:"Wireframes",
        heading9:"Storyboard",
        bullettitle1:"",
        bullettitle2:"",
        ul1:<><img className= " col-12 col-lg-8 mb-3 mx-auto" src="/img/gp/critscreen.jpg" /><br /></>,
        ul2:<><p>The user typically doesn’t know much about the pieces but a handful have done a lot of research and want to hear more about the art from another perspective. As a guide, the goal is to help the user create a story of the piece in their mind - background of the artist, fun facts,how they became an artist or what inspired the piece. Since the goal is to emphasize the in person experience- using audio to supplement the experience is the best alternative. </p><br /></>,
        process1: "For research, I created a Journey map of Museum attendees and lightning demos for apps that have similar functions to GalleyPals.",
        process1_2:" ",
        process1_3:"I used apps such as Spotify, Discord and the Louvre museum app to identify components and patterns that would be useful to incorporate in GalleryPals.",
        process1_4:<u>Louvre Museum</u>,
        process1_5:" ",
        process1_6:"I chose the critical screen by centering the app around a single piece of art that the user can either read or listen to the necessary information they want to hear about. The screen before can be navigated based on the location in the museum. ",
       process1_7: "I decided to create a design that focused on the primary use of a toolbar with these main components for navigation: Search bar, artwork/exhibits, artist, location/map and saved artists/exhibits/artworks. The entries for the artworks and artists all feature an audio icon that the user can easily press to hear all of the content or they could also choose to listen to select portions - thus making it easy for the user to stay fully immersed in their museum experience and locate what they’re looking for with ease.",
       process1_8:"The primary obstacle that the users faced was in navigating to the exhibits page. Most folks didn’t seem to understand the meaning of the exhibits icon and confused it with the carousel. Other icon, such as the artist icons, were easily located and used appropriately. The rest of the tasks were also straightforwardly located and correctly used e.g. finding location of certain arts and adding to favorites. The primary changes applied to the final iteration are:",
        process2:"There are different channels for discord, so they can easily be accessed to filter out what content the user is looking for. This would be good to filter out what sort of works an author has, audio, discussion board and more. On the left hand side there is a list of users so artists could be sorted in a format similar to that but have a whole row to show pictures, their name and style of art.",
        process3:"I converted the storyboard into B&W Low-Fidelity wireframes after sketching everything out so I could begin testing and iterating the design based on the test feedback.",
        process4:" Five users were interviewed and asked to complete tasks such as locating an exhibit, its artist, the location and saved content. These users ranged from museum goers who are mobile-savvy millennials to boomers not as familiar with apps.",
        process5:"After testing the prototype, I realized that there was no home icon and the home page was lacking. To remedy this flaw, I combined the search page with the home page and allowed for more continuous scrolling on the landing page so the user can have multiple options for choosing what to explore.  I decided that adding words to the navigation would help the user understand the navigation better. I included pictures of what the user was viewing on the map to help them better understand where each artwork is and what it looks like.",
        process6:"",
        process7:"",
        process8:"",
        process9:"",
        process10: "",
        process11:"",
        process12:"After identifying user needs and conducting lightning demos of other app designs- I used some of those traits in my Crazy 8’s design( a quick sketch of 8 potential screens). These sketches feature a list of artwork, the artwork main page, a map, artists page and potential navigation bars. ",
        process13:"",
        process14:"",
        process15:<p> The next step would be to create a style guide that I can apply to the wireframes.</p>,
        process16:"",
        process17:"I decided to use a limited color palette for the app. I wanted something that felt artistic and bold but didn’t detract from the art pieces. I settled on using complementary colors for the main two colors to highlight navigation and icons in the app. I used a less saturated version of these colors so that the focus would be on the artwork. ",
        process18:"I transformed the wire-frame into high fidelity by applying several design patterns of mobile first design and the style guide. Specifically, the following changes were included:",
        process19:<img className= " col-12 mb-3 mx-auto" src="/img/gp/hf.png" />,
        process20:<p>Due to only having a week to design - most of the testing occured after the high fidelity design was produced.</p>,
        bullet1_1:<li><b>Home Page: </b> There is no idetifier for the home page, Users could not navigate back to it.</li>,
        bullet2_1: <li><b>Search:</b>  Users wanted to be able to browse more categories immediately. Users did not find recent searches much helpful.</li>,
        bullet3_1: <li><b>Navigation:</b>  Users didn't understand what some the icons symbolized. </li>,
        bullet4_1: <li> <b>Location:</b>  Users wanted to see the picture on the map of the piece. </li>,
        bullet7_1:"",
        bullet8_1:"",
        bullet9_1:"",
        bullet10_1:<p><u>Spotify</u></p>,
        bullet11_1:"Similar to a visual gallery, spotify is just a gallery for audio. In order to prioritize the experience of being present in the museum, an audio tour would be ideal for museums. If the collections were sorted like albums, and ordered based on a certain path or numbered - the user would be able to look at the artwork and listen to the recording just as if there was a tour guide with them.  There could be two sorts of descriptions provided- one based for seasoned art enthusiasts with pre-existing knowledge and the other for those who just want to learn more generally.",
        bullet12_1: <img className= " col-12 col-lg-8 mb-3 mx-auto" src="/img/gp/spotify.png" />, 
        bullet1_2:"",
        bullet2_2:"",
        bullet3_2:"",
        bullet4_2:"",
        bullet5_2:"",
        bullet6_2:"",
        bullet7_2:"",
        bullet8_2:"",
        bullet9_2:"",
        bullet10_2:<li>Which navigation structure works best for an online art shop?</li>,
        bullet11_2:<li>What elements should be displayed on the home page?</li>,
        bullet12_2:<li>How can I accommodate the budget of customers?</li>,
        bullet13_2: <u>Discord</u>,
        bullet1_3: " ",
        bullet2_3:" ",
        bullet3_3:"My design should be able to have a way to encompass all user needs. These needs include : a way to browse art/artists, View piece overview, listen to artist recording, view background of piece, view artists facts, view expert input, view artist bio, view intention and inspiration for pieces, view techniques and a way to sort by themes/genres or popularity.",
        bullet4_3: "",
        bullet5_3: "This app shows a map of where certain artworks are by featuring icons. This will be helpful for navigating museums that are as expansive as the Louvre and also listening to audio summaries for each specific piece.",
        bullet6_3: <img className= " col-12 col-lg-8 mb-3 mx-auto" src="/img/gp/louvre.png" />,
        bullet7_3: "",
        bullet8_3: "",
        bullet9_3: <img className= " col-12 mb-3" src="/img/gp/wire.png" alt=""/>,
        bullet10_3: "",
        bullet11_3: "",
        bullet12_3: "",

        bullet21_3: <li> Add rounded corners to tiles</li>,
        bullet22_3: <li> Changed icon color on navigation to represent selected state </li> ,
        bullet23_3: <li> Added more padding for elements</li> ,
        bullet24_3: <li> Changed font size + spacing for each card to avoid visual clutter</li> ,
        bullet25_3: <li> Added outlines and drop shadows for tile and card elements. <br/><br/> </li> ,
        bullet26_3: "" ,
        summary:"Overall, more testing could have been done to further determine the needs of multiple Museum attendees. However, with the time constraint of 7 days to finish this design sprint, I had to prioritize designing a high fidelity version based on research and testing once I had a high-fidelity product design. ",
        link: <a href="https://www.figma.com/file/fz447MQjiCO4nLxi7rOUzP/gplf?node-id=0%3A2" target = "_blank"> High Fidelity Prototype </a>,
        caption1:"",
        caption2:"",
        caption3: " ",
    },
    {
        id: 3,
        listingid: 3,
        logo:<img className= " col-6 col-lg-4 mx-auto" src="/img/snb/snblogo.png" alt=""/>,
        sketch1:<img className= " col-12 mb-3" src="/img/snb/companalsnb.png" alt=""/>,
        sketch2:<img className= " col-12 mb-3" src="/img/snb/snbsocialmediawireflow.png" alt=""/>, 
        sketch5:<img className= " col-12 mb-3" src="/img/snb/update1.png" alt=""/>, 
        sketch6:<img className= " col-12 mb-3" src="/img/snb/update2.png" alt=""/>, 
        sketch3:<img className= " col-4 col-xl-3 mb-3" src="/img/snb/update3.png" alt=""/>, 
        sketch4:<img className= " col-4 col-xl-3 mb-3" src="/img/snb/update4.png" alt=""/>,
         sketch12:<img className= " col-6 col-md-3 col-xl-3 mb-3" src="/img/snb/snbhi1.png" alt=""/>, 
        sketch13:<img className= " col-6 col-md-3 col-xl-3 mb-3" src="/img/snb/snbhi2.png" alt=""/>, 
        sketch14:<img className= " col-6 col-md-3 col-xl-3 mb-3" src="/img/snb/snbhi3.png" alt=""/>, 
        sketch15:<img className= " col-6 col-md-3 col-xl-3 mb-3" src="/img/snb/snbhi4.png" alt=""/>,
         sketch16:<img className= " col-6 col-md-3 col-xl-3 mb-3" src="/img/snb/snbhi5.png" alt=""/>,
          sketch19:<img className= " col-6 col-md-3 col-xl-3 mb-3" src="/img/snb/snbhi6.png" alt=""/>,
           sketch20:<img className= " col-6 col-md-3 col-xl-3 mb-3" src="/img/snb/snbhi7.png" alt=""/>,
        image1: <img className= " col-12 col-lg-6 mb-3" src="/img/snb/personas.png" alt=""/>, 
        image2: <img className= " col-12 mb-3" src="/img/snb/seednbeyondredroute.png" alt=""/>,
        image3: <img className= "col-12 col-lg-6" src="/img/snb/seednbeyondsketch 1.jpeg" alt="" />,
        image4:  <img className= " col-12 col-lg-6 mb-3" src="/img/snb/seednbeyondsketch 2.jpeg" alt=""/>,
        image5:  <img className= " col-12 mb-3" src="/img/snb/snbexplorewireflow.png" alt=""/>,
        image6: <img className= " col-12 mb-3" src="/img/snb/Moodboard.png" alt=""/>,
        image7:<img className= " col-12 mb-3" src="/img/snb/SNBhighfiedlity.png" alt=""/>,
        altname:"" ,
        movie:  "",
        problem:<><br/><b>Platform:</b> Mobile <br/><b>Role:</b> Product designer  <br/><b>Tools:</b> Sketch, Adobe Illustrator, Adobe Photoshop, Invision Studio <br/></>,
        problem1:"As a result of COVID, many folks are home-bound and exploring new hobbies such as gardening. Since they are usually novices, they have to peruse books, videos and websites but still get confused since plants are different for each region and season. Hence, I created the Seed N Beyond app to serve as a solution to assist gardeners find pertinent information, start a garden and share information with the community.  ",
        tools:<p><ul>  <li>Sketch</li> <li>Invision</li> <li>Photoshop</li></ul></p>,
        roles:"UI Designer, UX Designer",
        role0:<p><b>UI Design</b><ul><li>Designed Information Architect </li><li>Construct a flow diagram</li><li>Designed Information Architect </li><li>Use of multiple Sketches</li><li>Created Wireframes</li><li>Designed low fidelity prototype</li><li>Created a Style Guide</li><li>Conducted comparative analysis</li><li>Created a Style Guide</li><li>Created Branding through the use of logo, icons, mission statement and brand values</li><li>Designed interactive high fidelity prototype</li></ul> </p>,
        role1:<p><b>UX Design</b><ul><li> Accessibility Audit using Web Content Accessibility Content Guidelines (WCACG) </li><li>Research secondary resources</li><li>10 user surveys,conducted </li><li>5 user interviews</li><li>Identified 2 personas</li><li>Constructed user stories</li><li>Constructed affinity diagrams</li><li>Conducted comparative analysis</li><li>Guerilla testing 5 users for low fidelity wireframes</li><li>Scenario testing 5 users for the mid fidelity prototype</li><li>A/B testing 5 users for high fidelity prototype</li></ul> </p>,
        role2:"",
        research:<div className = "col-12 col-md-10 col-xl-4 mx-auto"><ReactPlayer url='/img/snb/snbdemo.mov' width= '100%' height='7%' volume='0' controls = {true}/></div>,
        title1:'Research',
        title2:"Initial Design",
        title3:"Low Fidelity design",
        title4:"High Fidelity design",
        title5:"Summary",
        bullettitle1:<> <h5 className= "mb-4">Post User testing changes - Round 1 (5 participants)</h5></>,
        bullettitle2:"Final User testing - Round 2 (5 participants)",
        process1: "I used a series of 'How might we' questions to try and define the main research goals of this app.",
        process1_2:" I sent out a screener to 10 people to find at least 5 candidates suitable for interview. These 5 candidates were sorted into two primary categories which I created Personas for based on their tech saviness, experience, goals and current household breakdown. ",
        process1_3:" All users wanted to learn more about the vegetables and herbs that would grow well outside their house but didn’t know where to get started. Users wanted to have information that was easy to parse through and guided them through each step of the growing process.",
        process1_4:" Below are some quick sketches I used for Guerilla testing. My goal was to see how easy it was to navigate to complete the steps of the red routes and identify what the app could use as additional features. ",
        ul1:<u>Primary route: Growing guide</u>,
        ul2:<u>Secondary routes:  Community and Journal</u>,
        process1_6:"After guerilla testing, I’ve realized that users have their needs organized in this hierarchy: ",
       process1_7: "Users wanted to have a way to easily refer to plants they wanted to grow. They wanted a community to discuss their experiences and a place where they can ask questions. Users wanted to track their progress of growing plants at each stage and write notes on how their observations were going.",
        process1_5:" Three competitors – WikiHow, Almanac and Gardeners Path– were analyzed for UI/UX heuristic principles and rated 1(bad)-5(good).",
       process1_8:"The biggest change from the low fidelity prototype is implementing a standard navigation bar all across the app. Changes for better accesbility include conducting a Web Content Accessiblity Guidelines(WCAG) test in order to determine if the colors contrasted well, icon size was big enough and whether font size was large enough for readability. For page specific changes, the most notable were the plant page and journal. The plant page would had all icons moved underneath the carousel and the steps for plant growth were collapsable and expandable. The journal page featured colorful status indicators around a face and a more detailed post for each plant so the user could chart their progress. ",
        process2:"I further whittled down the initial ten choices to the following five based on the ones that had that had the best navigational components:",
        process4:" Seed n Beyond's mission - Once humans were able to cultivate the land, they were able to easily provide sustenance for the community. It’s important to understand your roots. We can provide you with information to grow plants and reap the rewards. I wanted the user to feel hopeful when they were using the app- so the primary color used was yellow with lime green as a secondary color. I wanted the app to feel welcoming and upbeat.   ",
        process5:"Users were overall able to finish tasks up to 30% faster after implementing new changes in the first round of testing and were able to complete the tasks asked of them very quickly. After conducting A/B testing with a couple of users from before the first round of high fidelity testing vs after the second round- almost all users preferred the prototype after the second round. ",
        process6:"",
        process7:"",
        process8:"",
        process9:"",
        process10: "",
        process11:"",
        bullet1_1:<li>Sign in with keyboard when an entry field is clicked </li>,
        bullet2_1: <li>Expanding 'Sort By' Button on plant category and journal pages</li>,
        bullet3_1: <li>Interactable Photo Carousel</li>,
        bullet4_1: <li> Adding Share and Journal Icons to every screen to improve conversion rates </li>,
        bullet5_1: <li>Having notifications for amount of new messages on navbar</li>,
        bullet6_1: <li>Showing which messages are unread on messages page</li>,
        bullet7_1:"",
        bullet8_1:"",
        bullet9_1:"",
        bullet1_2:<li>Having photos of the plants on journal page</li>,
        bullet2_2:<li>Having a profile picture on the feed page</li>,
        bullet3_2:<li>Having a fixed edit button as user scrolls through the plant page</li>,
        bullet4_2:<li>Bigger Photo Carousel</li>,
        bullet5_2:<li>Make the plants category /home page icon more apparent to the user</li>,
        bullet6_2:<li>Have a back button for the plants page to redirect back to the categories</li>,
        bullet7_2:"",
        bullet8_2:"",
        bullet9_2:"",
        bullet1_3: <ul><li>How might we teach people to grow vegetables and herbs with little space and resources?</li></ul>,
        bullet2_3:<ul><li>How might we teach people that have never grown anything from a seed to successfully produce an edible deliverable?</li></ul>,
        bullet3_3:<ul><li>How might we bridge the gap of knowledge on how to take care of certain plants when it comes to water, sun and soil?</li></ul>,
        bullet4_3: <ul><li>How might we design and incentive for people that don’t have much time in their day to share their knowledge with others?</li></ul>,

        summary:"As I was developing this app, it seems like more people desired a sort of community as the months passed in quarantine and many people were beginning to start their own garden. If I had more resources for this project I would refine the social media route to reflect the needs of all the users better- however the main route for this project was the reference guide of growing the plants.",
        link: <a href="https://www.sketch.com/s/ed0e52b3-7883-4b45-8126-621f49856d7c" target = "_blank">High Fidelity Prototype</a>,
        caption1:<p>Above: Personas composed of 5 interviews </p>,
        caption2:"Above: Main red routes taken by users",
        caption3: " Above: A screenshot of the class details page",
    },
    {
        id: 4,
        listingid: 4,
        image1: <img className= " col-12 mb-3" src="/img/ucsc/lowfi.jpg" alt=""/>, 
        image2: <img className= " col-5 mb-3" src="/img/ucsc/picture6.png" alt=""/>,
        image3: <img className= " col-7 mb-3" src="/img/ucsc/picture5.png" alt=""/>,
        image4:  <img className= " col-7 mb-3" src="/img/ucsc/picture4.png" alt=""/>,
        image5: <img className= " col-4 mb-3" src="/img/ucsc/picture3.png" alt=""/>,
        image6:<img className= " col-6 mb-3" src="/img/ucsc/picture2.png" alt=""/>,
        image7:<img className= " col-6 mb-3" src="/img/ucsc/picture1.png" alt=""/>,
        
        altname:"" ,
        movie: "<video  controls src ='/img/ucsc/HighFidelityPrototype.mp4' width='100%' />",
        problem:"The current UCSC enrollment website is difficult to navigate and students have a hard time figuring out their enrollment time. Students should be able to intuitively find their enrollment time and find the classes they are looking for. Many students have to ask advisors and friends specific questions in order to learn how to use the layout thus using up more resources from the campus that could be better allocated for other students. ",
        problem1:"A redesign is imperative as it will speed up the amount of time a student takes to enroll in classes, free up university resources used to show students how to enroll and also give users an opportunity to visualize their school schedule better.",
        tools:"Adobe XD",
        roles:"Part of a team of 4",
        role0: "",
        role1:"UI/UX Designer - redesigning a low fidelity and high fidelity prototype through the use of iterative design",
        role2:" Research - current website, competitors, interviewed at least 4 students, and identify targeted persona needs",
        research:"Error Analysis, Narrative, Scenario Testing and Fly on a wall",
        title1:"Error Analysis to identify faults in the current UCSC Enrollment Wesbite",
        title2:"Narrative to understand what the user is thinking and the task they are trying to complete",
        title3:"Scenario Testing to see how long it will take users to complete tasks in comparison to old UCSC prototype and our new prototype",
        title4:"Fly on the Wall to see how the user interacted with the paper prototype without any help but the given task.",
        title5:"Conclusion",
        bullettitle1:"Research goals: identify what is causing an issue in the old enrollment website.",
        bullettitle2:"Changes to be implemented after testing low-fidelity prototype",
        process1: " I conducted a user test focused on the Error Analysis IDEO method. For the first page(Enrollment Dates, Shopping Cart and Weekly schedule) the user wanted to know the number of classes being taken and potentially the amount of units displayed at the top of the shopping cart, For Enrollment Dates, the user would like to have a timezone listed next to the times and they also realized that the two S’s on the calendar weren’t specific so something like Sat or Sun would be a better label for the Weekly Schedule element in the first page. For the second page (Class search with all search field categories and Academic Advising Report) the user wanted to have the new SOE labeling system implemented for the classes under the Academic Advising Report and Class Search. They also wanted the requirements for the major based on the requirements of that year and have the option to pick between them (for example game design requirements in 2017 are different from 2019). For the third page(Class search showing list of classes), the user would like to have classes that are conflicting with the current classes shown prior to adding it to their calendar- perhaps having a red outline around the class in class search would be helpful. For the last page (Confirmation details of class enrollment), the user would have liked to have known if a class was full before they clicked the “Waitlist if full” tab on the same page so they could know if it was an option. ",
        process2:" The “Narrative” IDEO method was also used for research on this Low-Fidelity analyses. I asked the test user to go through the various stages of the paper prototype and think out loud on his thought process. User first recognizes that the enrollment page displays enrollment dates and times right away. He then clicks on the “Add” button within the shopping cart section that takes him to the class search page. Here the user notices the addition of the Academic Advisement Report and the real time scheduler and then comments on how useful it is to have it placed there. He then searches for a class which updates the search window rather than going to a whole other page and recognizes the “update schedule” buttons on the bottom right of each class result. User then comments saying he assumes the update schedule button updates the calendar window to the right which is great feedback - telling us that it is designed intuitively enough. User then states that the school site really does need a visual representation of class schedule in order to see time conflicts easier. User then clicks on a class which takes him to the class details page noting that there are new features such as “Professor” contact and office hours, and the “class resources” which contains hyperlinks to syllabus, books, and other resources. User then clicks on “Add” which takes him to the section selection page commenting that it is nice how sections are displayed in calendar view as opposed to just the list of possible sections. He then clicks on next which takes him to the confirmation page which isn’t changed much. Finally he clicks “enroll” which takes him back to square one, the “Enrollment Page”. ",
        process3:"Scenario Testing was used when I tested our low-fidelity prototype. Like the previous time this method was used, the scenario for this test was finding a class to satisfy a GE requirement that was currently unsatisfied, only now the test was done with another user. I decided to use this method again, as it acted as both an applicable and relevant test for the user, but also allowed us to better compare how a user would run through the scenario with our prototype in comparison to the current MyUCSC website. Aside from explaining the basic scenario, I found myself not needing to explain anything more than that, as my user was able to quickly understand and navigate our prototype with relative ease. After running through our scenario, my user noted that the addition of seeing lecture and section times in calendar view at nearly all times, as well as seeing potential scheduling conflicts, made the enrollment process much easier. However, he also did note that one way our prototype could be improved is by including a way of going back, and seeing where multiple courses would fit on a calendar rather than just the one that was selected; this could help with schedule planning, as well as give users a way of having backup courses and schedules. The lack of an ability to go back a page in our prototype was an oversight on our part, but having the ability to look at where multiple courses fit on a calendar as my user described was something that never crossed my mind when we made our prototype, so this is definitely something worth taking into account when designing our high-fidelity prototype. ",
        process4:" For our last IDEO method, we used the “Fly on the Wall” method to test our paper prototype. We used this method because we wanted the user to go through our low fidelity prototype without interference. By doing this, we can see where our user gets confused or experience negative feelings. This method also gives us insight into the user’s mental process. When we first presented the paper prototype to our user, the user understood each button and process. The user started off by clicking “Enrollment” from MyUCSC. From there on, the user recognized the enrollment page and the information such as the enrollment date and units. After the user realized they could start adding classes, the user clicked on the “Add” button which led to the class search page. The user did not interact with the search page as much but he user mentioned how they like the “Academic Advisement Report” feature that pops up in the class search page. The user stated that they liked it because they wouldn’t have to switch between pages. After the user searched the class, they clicked “Update Schedule” that updated the real-time schedule. However, the user stated “when I click, update schedule, that would add the class.” This was not the intention of our interface, so this is something to consider when we design our high fidelity prototype. After the user entered the class detail page, the user did not read through the details. They just clicked “Add” and proceeded to the section time and confirmation page. There was nothing notable during the last part of the process; it went very smooth. Some things to consider for our redesign is to alter design when adding classes. “Update Schedule” should not indicate a class being added. We should also make the class detail page more obvious so the user would look it through. ",
        process5:"After evaluating our user test reports, these were the general changes we decided to implement across all of the webpages: A back button and Aesthetic (font and color scheme). In our low fidelity prototype, we forgot to add a back button. After several users pointed out the absence of that feature, we realized the importance of it and had to implement it. The purpose of the back button is to serve as a tool to refer back to information in previous pages as well as an error prevention tool for users who accidentally clicked forward. This is a very important feature that should not be overlooked. That is why we decided to include it in our high fidelity prototype. ",
        process6:"As for the aesthetic, we wanted to maintain a consistent font and color scheme. We wanted to change the color scheme because our low fidelity prototype was black and white. We wanted our high fidelity prototype to reflect the university’s color. We also picked a font to display professionalism. ",
        process7:"When comparing our low-fidelity prototype to our new high-fidelity one, not much needed to be added or improved upon from our first prototype. Not including the minor changes that come with translating a paper prototype to a digital format, many of the pages for our enrollment website got upgraded mostly in their aesthetic. This was done to maintain consistency across all of the pages we made, as well as give the website a new and refreshing look. However, that is not to say that no changes were made between prototypes. In comparison with the low-fidelity prototype, the placement of buttons such as the button to go back a page, or the button to add a class in the “Class Details” page, has been slightly adjusted. The button for going from the “Section Times” page to the final “Confirmation” page has been removed entirely. Since the section times are now shown in a calendar rather than a list, we found that simply clicking on the desired section was a simpler and more intuitive means of going to confirm the course and section. A more significant change was one that was added to the real-time calendar that appears when searching for classes. One tester of our low-fidelity prototype proposed the idea of allowing users to add multiple classes to the real-time calendar rather than just one, as that could help with planning out one’s schedule before committing to enrolling. We found a way to implement this by creating a set of tabs for separate schedule plans for the users to experiment with. This gives users the ability to look at multiple classes and schedules without cluttering the calendar.",
        process8:"After looking over all of our user test reports in depth, we decided to first implement the ability to select between different time zones when choosing an enrollment term. Users said it would be a nice addition incase a student happens to be away during an enrollment period or an international/transfer student. Next, we made the enrollment appointment dates and times appear at the very top of the page after confirming enrollment term and time zone so that it is very clear to the user if they can enroll for that term as opposed to the current portal where the student has to navigate to a completely different page just to see time a date. Under that section is the shopping cart which hasn't changed much except for a much nicer looking interface than the current portal’s. Under the shopping cart we have the student’s weekly schedule in calendar view by default rather than list view on the current portal’s page. After clicking “add” in the shopping cart section, the user is then taken to the newly improved class search page. ",
        process9: "This new class search page has the search field categories seen in the current page however, it is moved all the way to the left to make room for the two new sections featured in our paper prototype: the “Academic Advisement Report” and the “MyScheduler” real time calendar. The Academic Advisement Report is located on the top right of the page and is split into two smaller sections labeled “Incomplete Classes” & “Completed Classes” both with drop down menus listing “Major requirements” and “General education”. The “Major Requirements” drop down menu is specifically catered to that student’s major year curriculum. Major requirements change from year to year but do not change for the student once the student declares. This serves as a convenient guide for the student when they are not sure what to take. Under the Academic Advisement Report is the MyScheduler real time calendar that updates with class time information when the user clicks on the “update scheduler” button located in the class search results list. This is a useful tool as users can quickly test if a class works with their schedule based on classes that are already in the shopping cart or already enrolled in without having to go through the various steps. Currently students must either remember when their other classes were at or go through the entire process of searching a class, clicking add, enroll, chose section time, confirm, and then see if the portal has found a schedule conflict. The MyScheduler calendar also has the feature to add and create multiple schedules with different classes incase the student wants to experiment with schedules for that term. ",
        process10: "Next, we made an addition to the class details page displaying the class professor’s email incase the student wants to ask questions regarding the class before enrolling in it or to get a permission code if the enrollment is barred to certain students. There is also another addition where the “Class Resources” section has a link to the class syllabus incase a student wants to see the weekly plan and overall rigor for the class. ",
        process11:" Lastly, we made a change in the section selection page where it is displayed in calendar view rather than list view to visually represent which section times work best with the student. This is done similarly to the MyScheduler by displaying currently enrolled classes under the available sections and highlighting the conflicting sections red and highlighting the suitable sections green. The confirmation page stayed relatively the same to the current one as not much was wrong with it. ",
        bullet1_1: <li>MyScheduler tool was hard to locate (4 menu navigation errors)</li>,
        bullet2_1: <li> Users had to switch between multiple tabs to look for the information needed</li>,
        bullet3_1: <li>Materials were hyperlinked under the Class Search page but it only led to the Bay Tree Bookstore- not the product</li>,
        bullet4_1: <li>Could not find other materials such as the syllabus, MSI schedule, or class website</li>,
        bullet5_1: <li>There are too many words and the layout makes it hard for visual learners</li>,
        bullet6_1: <li>Students can’t see their schedules while signing up for other classes </li>,
        bullet7_1: <li>Students are shown the schedule before they even know their enrollment date</li>,
        bullet8_1: <li>Students can’t see their schedules while signing up for other classes</li>,
        bullet9_1: <li>The aesthetic of the website seems very drab and generic</li>,
        bullet1_2: <li>Less words and more visual information for easy navigation on the first enrollment page overall</li>,
        bullet2_2: <li> Enrollment times and dates to the first enrollment page and as well as a weekly schedule based on the classes you’ve enrolled in</li>,
        bullet3_2: <li>A small Academic Advising Report that would be divided based on completed and incomplete requirements for graduation and your major(s)/minor(s) on the class search page</li>,
        bullet4_2: <li>Larger “Add” buttons to the enrollment first page, “search” class and class details page</li>,
        bullet5_2: <li>A MyScheduler would be used to see if there are time conflicts while trying to add classes and it would be on the class search page</li>,
        bullet6_2: <li>After the class details page, there would be a scheduler telling you which section times work with your schedule, which are open and which are closed </li>,
        bullet7_2: <p><li>A class resources section will include a link to the syllabus, a link to the books or any other necessary material for the class</li></p>,
        bullet8_2: <li>The class confirmation page would also include a class preferences section that will include a waitlist if full, permission number, grading options and a swap class feature</li>,
        bullet9_2: <li>A professor’s category would also be under the class details page so you can see the professor’s office hours, website and contact</li>,
        summary:"After the redesign- users were able to complete scenario testing prompts for finding enrollment time and signing up for classes up to 5x faster than the old layout. All of the users(12) said they preferred the redesign compared to the old design. New Features that worked really well were showing visually your schedule before you sign up for it, the course details page, use of icons(add, search and home) and overall information hierarchy. Features that didn’t work is that the overall aesthetic could have been slightly better in regards to giving components more space for margins and padding. ",
        link: "https://xd.adobe.com/view/71d23601-1041-42f0-a7d6-6d35e779a7cc-2481/screen/a8a8e8b2-0e6f-468b-9b3c-f928486df88f?fullscreen&hints=off",
        caption1:"Above: A screenshot of the enrollment dashboard ",
        caption2:"Above: A screenshot of choosing a class discussion",
        caption3: " Above: A screenshot of the class details page",
    },
   
]