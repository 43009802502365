import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem,
    Button, Label, Col, Row } from 'reactstrap';
    import { Link } from 'react-router-dom';
    import { SRLWrapper } from "simple-react-lightbox";



const options = {
  settings: {
    overlayColor: "rgb(97, 100, 100,.9)",
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  buttons: {
     showThumbnailsButton: false,
     showAutoplayButton: false,
     showDownloadButton: false,
  },
  caption: {
    showCaption: true,
    captionColor: "#000000",
    captionFontFamily: "Raleway, sans-serif",
    captionFontWeight: "300",
    captionTextTransform: "uppercase",
  },
  thumbnails: {
    showThumbnails: false,
  }
};

    class Resume extends Component {


        render(){


            return (



                <div className="container ">
                    <div className="row ">
                        <div className="col text-center">
                        <Breadcrumb className = "col-12 breadAlign">
                        <BreadcrumbItem ><Link to="/home">Home</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Resume</BreadcrumbItem>
                        </Breadcrumb>

                        </div>
  </div>
                        <div className=" mx-auto col-12">

                            <SRLWrapper options={options}> 
                                <img className="lcard col-12 mx-auto" src="/img/resume.png" alt=""/>
                            </SRLWrapper>
                        </div>
                  
                </div>
                );
        }
    }
    export default Resume;