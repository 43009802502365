import React from 'react';
import { Card, CardImg, CardText, CardBody, CardTitle, Breadcrumb, BreadcrumbItem,Carousel,CarouselItem, CarouselControl,
  CarouselIndicators, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactPlayer from "react-player";
import { SRLWrapper } from "simple-react-lightbox";
import '../App.css';

function RenderDesign({design}) {
    console.log("DesignwithId");
    return(
        

        <img className= "banner col-md-12 col-lg-8 " top src={design.image} alt={design.name} />

        );



    }

const options = {
  settings: {
    overlayColor: "rgb(97, 100, 100,.9)",
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  buttons: {
     showThumbnailsButton: false,
     showAutoplayButton: false,
     showDownloadButton: false,
     showNextButton: false,
     showPrevButton: false,
  },
  caption: {
    showCaption: true,
    captionColor: "#000000",
    captionFontFamily: "Raleway, sans-serif",
    captionFontWeight: "300",
    captionTextTransform: "uppercase",
  },
  thumbnails: {
    showThumbnails: false,
  }
};




    function RenderComments({designinfo}) { 
        console.log(designinfo);
        if(designinfo){
            return(
            <div className = ' '>

            {designinfo.map(info=> {
                return(
                <div infolist = {designinfo.id}>
                <div className ="row">
                {info.logo}
                <br />
                <div className ="icard col-12">
                <h5 className= "mb-2"> Overview </h5>
                <p>{info.problem} </p>
                <p>{info.problem1} </p>
                </div>   
                <br />


              
                <div className ="icard col-12">
                <h5 className= "mb-2"> Process </h5>
                <h3><b>{info.roles} </b></h3>
                
               <b> {info.role0}</b>
                 {info.role1} 
                {info.role2}
                
                <br/>

                </div> 
           
              

                <div className= "trailer">
                {info.movie}
                </div>
                <br />
                <a className= "imgcenter h3 text-center" href= {info.link}>{info.altname}</a> 
                <br />
                 <div className ="icard col-12">
                <h5 className= "mb-2"> {info.title1}</h5>
                <br />

                <p>{info.process1} </p><br/>
                <p><h3>{info.heading}</h3></p>
                <p> {info.bullet1_3} </p> 
                <p>  {info.bullet2_3}</p>
                <p>   {info.bullet3_3}</p>
                <p> {info.bullet4_3} </p> 
                <br/>

                <p>{info.process1_5} </p>
                <SRLWrapper options={options}>
                {info.sketch1} 
              {info.sketch11} 
                {info.sketch7} 
            
                <p>{info.process1_2} </p>

                
               <p><h3> {info.heading1} </h3></p>


                

             
                <p>{info.process1_3} </p> 
                {info.image1} 
                {info.caption1}
               {info.bullet10_1}
                <p>{info.bullet11_1}</p>
                {info.bullet12_1}
                <br />
                <ol>
                {info.bullet7_5}
                <p>{info.bullet8_2}</p>
                <p>{info.bullet9_2}</p>
                <h5 className= "mb-2"> {info.title2}</h5>
                </ol>
 
                <br />

                <p>{info.bullet13_2}</p>
                <p>{info.process2} </p>
                {info.image2}

  
                <br /> 
                <h3>{info.caption2} </h3>

                <p>{info.process1_4} </p>
                <ol>
                <p>{info.bullet5_3}</p>
                {info.bullet6_3}
                {info.bullet7_3}
                {info.bullet8_3}
                {info.bullet9_4}
                {info.bullet10_4}
                </ol>
 </SRLWrapper>
</div>

   <div className ="icard col-12">
         <SRLWrapper options={options}>
                <h5 className= "mb-2"> {info.title6}</h5>
                <br/>
                <p><h3> {info.heading2}</h3></p> 
        
                <p>{info.process12} </p>
                {info.image3}
                {info.image4}
                <p>{info.process1_6} </p>
                {info.ul1}
                {info.ul2}

        

                <h5 className= "mb-2">{info.title8}</h5>

                <p><h3> {info.heading9}</h3></p> 
                <p>{info.process1_7} </p>
                <p><b>{info.process13}</b> </p>
                {info.sketch8} 
                <p>{info.process14} </p>
                {info.sketch9} 
                {info.sketch9_1} 
                {info.sketch9_2}
                <br />


                <h5 className= "mb-2"> <br />{info.title3}</h5>
                <p><h3> {info.heading7}</h3></p> 
                
                <p>{info.process3} </p>
                <p>{info.bullet9_3}</p>
                <p>{info.bullet10_3}</p>
                {info.bullet11_3}
                    {info.process15} 

                <ol>
                <p>{info.bullet12_3}</p>
                <p>{info.bullet13_3}</p>
                <p>{info.bullet14_3}</p>
                <p>{info.bullet15_3}</p>
                </ol>
                <p>{info.process16} </p>
                <p>{info.bullet16_3}</p>
                <p>{info.bullet17_3}</p>
                <p>{info.bullet18_3}</p>
                <p>{info.bullet19_3}</p>
                <p>{info.bullet20_3}</p>
</SRLWrapper>
</div>
  <div className ="icard col-12">
  <SRLWrapper options={options}>
                <h5 className= "mb-2"> {info.title7}</h5>
              <p><h3> {info.heading3}</h3></p> 
                <p>{info.process17} </p>
                {info.image8}
                {info.image5} 
                {info.sketch2} 
                {info.sketch10} 
                {info.ex1}
                {info.ex2}
                {info.ex3}
                {info.ex4}

                <br />

                <p><h3>{info.heading4}</h3></p> 
                <p>{info.process18} </p>
                <p>{info.bullet21_3}</p>
                <p>{info.bullet22_3}</p>
                <p>{info.bullet23_3}</p>
                <p>{info.bullet24_3}</p>
                <p>{info.bullet25_3}</p>
                {info.sketch17} 
             {info.sketch18}

                <p><h3>{info.heading5}</h3></p>
                {info.process19} 
                <h3>{info.doclink} </h3>
                {info.process20} 

                <h3><p>{info.caption3} </p></h3>
                <br />
    </SRLWrapper>
</div>
     <div className ="icard col-12">   
     <SRLWrapper options={options}>          
                <h5 className= "mb-2"> {info.title4}</h5>
                <br />
                <p><h3>{info.heading6}</h3></p>
               
                <p>{info.process4} </p>

                {info.image6}
                <p>{info.process1_8} </p>
                {info.image7}
                {info.bullettitle1}
            
                <ul>
                {info.ex8}
                {info.bullet1_1}
                {info.bullet2_1}
                {info.bullet3_1}
                {info.bullet4_1}
                {info.bullet5_1}
                {info.ex9}
                {info.bullet6_1}
                {info.bullet7_1}
                {info.bullet8_1}
                {info.bullet9_1}
                {info.ex10_1}
                {info.ex10}
                {info.ex11_1}
                {info.ex12_1}
                {info.ex13_1}
                {info.ex11}
                {info.ex14_1}
                {info.ex15_1}
                {info.ex16_1}
                </ul>

                {info.sketch3}
                {info.sketch4}
                {info.sketch5}
                {info.sketch6}
                {info.sketch12}
                {info.sketch14}
                {info.sketch13}
                {info.sketch15}
                {info.sketch16}
                {info.sketch19}
                {info.sketch20}
                {info.sketch21}
                <br /><br />
                <h5 className= "mb-2"> {info.bullettitle2}</h5>
                <br />


                <ul>
                {info.bullet1_2}
                {info.bullet2_2}
                {info.bullet3_2}
                {info.bullet4_2}
                {info.bullet5_2}
                {info.bullet6_2}
                {info.bullet7_2}
                {info.bullet8_2}
                {info.bullet9_2}
                </ul>


                <br />
                <p>{info.process5} </p>

                <p>{info.process6} </p>
                <br />
                <p>{info.process7} </p>

                <p>{info.process8} </p>

                <p>{info.process9} </p>

                <p>{info.process10} </p>

                <p>{info.process11} </p>
</SRLWrapper>

  <br />

                <div className ="icard col-12">
                <br />
                <h5 className= "mb-3"> Final Prototype</h5>
                <p>{info.research} </p>
                <br />

                <div className= "text-center">
                <u><h5 className =  "mb-2 ">{info.link} </h5></u>
                </div>
                  </div> 
</div>
     <div className ="icard col-12"> 
                <h5 className= "mb-2"> {info.title5}</h5>
<br/>
                <p>{info.summary} </p>
</div>
                <br />
                  </div>  
     
                </div>
                );
            })}
            </div>
            )
        };
        return <div/>
    }


    function DesignInfo(props) {
        if (props.design) {
            console.log(props.design);
            return (
                <div className=" container">
                <div className="col-12 ">
                    <Breadcrumb className= "breadAlign col-6 mx-auto">
                    <BreadcrumbItem active className= "mx-auto" ><Link to="/design">Design / {props.design.name}</Link></BreadcrumbItem>
                    </Breadcrumb>
                    <div className = "text-center pt-5 pb-3  col-lg-6">
                       <h6 className= " mx-auto my-0">   {props.design.name} </h6>
                    </div> 
                    <RenderDesign design={props.design} />
                   

                    
                    <RenderComments designinfo={props.designinfo} />
                </div>
           
                </div>
                );
        }
        return <div />;
    }


    export default DesignInfo; 