export const DESIGNS = [
         {
            id: 0,
            name: "HiHome",
            image: "/img/hh/hhhero.png",
            description: "Desktop product design for personalized instant home matching tool"
        },

        {
            id: 1,
            name: "LucidLobo",
            image: "/img/ll/heroll.png",
            description: "Mobile product design for an art E-commerce store "
        },
        {
            id: 2,
            name: "GalleryPals",
            image: "/img/gp/gphero.png",
            description: "Mobile UI/UX for a museum app "
        },
        {
            id: 3,
            name: "Seed n Beyond",
            image: "/img/snb/snbhero.png",
            description: "Gardening social media mobile app"
        },
        /*
        {
            id: 4,
            name:"UCSC Course Enrollment",
            image: "/img/ucsc/enrollment.png",
            description: " Redesign for the UCSC enrollment website."
        },*/
        
    ];