
import React from 'react';
import { Card, CardImg, CardTitle, Breadcrumb, BreadcrumbItem, CardBody, CardText } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';


function Landing(props) {

    const landing = props.designs.map(design => {
        return (

            <landing className= "col-6" key={design.id}>

                <Card className = "lcard  ">
            <Link to={`/design/${design.id}`}>
           
            <CardImg className="" src={design.image} alt={design.name} />
             <CardBody className= "col ">
                <CardTitle className= " mt-3 text-center">{design.name}</CardTitle>
               <h2> <CardText className= "text-center mt-1">{design.description}</CardText> </h2>
            </CardBody>
            </Link>
         </Card>

            </landing>
        );

    });
    return(


 
<div className = "container">
    <div className = "intro">
         
      Evita solves user problems and provides <a href = "/design" target= "_blank" className= "underlinecolor">design</a> soultions. 
</div>
    
    <div className = "row">
          
        {landing}
       
    </div>
</div>

     );
}


export default Landing; 