import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (

        <footer className="site-footer">
`        <div className="container">
            <div className="row">      
                    <div className="col-12  text-center d-block d-lg-none">
                       
                        <ul className="list-unstyled ">
                            <h4>
                            <Link to='/about'>About</Link> <p/>
                            <Link to='/design'>Design</Link><p/>
                            <Link to='/games'>Games</Link><p/>
                          
                             </h4>
                        </ul>
                    </div>
  
                    <div className="col-12  my-1 text-center">

                        <ul className="list-unstyled text-center">
                            <h6>
                                
                            <a href="https://www.linkedin.com/in/evitalobo/" target = "_blank"><i className="fa fa-linkedin fa-lg my-2 mx-3"></i></a>
                            <a href="https://github.com/Evitalobo" target = "_blank"><i className="fa fa-github fa-lg my-2  mx-3"></i></a>
                           <a href="mailto:evitarlobo@gmail.com" target = "_blank"><i className="fa fa-envelope fa-lg my-2  mx-3 "></i></a>
                            </h6>

                            <p className= " text-center my-3 mx-auto"> Portfolio designed and developed by Evita Lobo </p>
                        </ul>
                    </div>
            </div>
        </div>
    </footer>
 
    );
}

export default Footer;